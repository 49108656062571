import React from "react";
import "./WorldWideStudios.scss";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

const WorldWideStudios = () => {
  const navigate = useNavigate();
  return (
    <div className="world-wide-section">
      <div className="world-wide-container">
        <div className="top-section">
          <div className="heading">
            <span> World wide studios </span>
            <br />
            delivered over 10M+ images
          </div>
        </div>
        <div className="middle-section">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div className="col-1">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Wroclaw, Poland</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/0")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-2 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">New York, USA</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/1")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-3 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Los Angeles, USA</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/2")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-4 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Milan, Italy</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/3")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-5 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Berlin, Germany</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/4")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-6 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Sofia, Bulgaria</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/5")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-7 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Manila, Philippines</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/6")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-8 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Bengaluru, India</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/7")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-9 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">New Delhi, India</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/8")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-10 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Beijing, China</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/9")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-11 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Hong kong, China</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/10")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-12 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Bangkok, Thailand</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/11")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-13 col">
                <div className="button-container">
                  <div className="button-section">
                    <div className="text">Dhaka, Bangladesh</div>
                    <Button
                      className="view-more-btn"
                      onClick={() => navigate("/studio/12")}
                    >
                      View more
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="bottom-section">
          <div className="cards">
            <div className="card">
              <div className="card-item">
                <div className="bold">1567sq</div>
                <div className="text">
                  Capacity of
                  <br />
                  the studio
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-item">
                <div className="bold">16+</div>
                <div className="text">Brands per week</div>
              </div>
            </div>
            <div className="card">
              <div className="card-item">
                <div className="bold">20+</div>
                <div className="text">
                  Photographer <br />
                  Experience
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorldWideStudios;
