import React from "react";
import "./ECommerceProduct.scss";
import { useParams } from "react-router-dom";

import p1 from "../../assets/images/p1.webp"
import p2 from "../../assets/images/p2.webp"
import p3 from "../../assets/images/p3.webp"
import p4 from "../../assets/images/p4.webp"
import p5 from "../../assets/images/p5.webp"
import p6 from "../../assets/images/p6.webp"
import p7 from "../../assets/images/p7.webp"
import p8 from "../../assets/images/p8.webp"
import p9 from "../../assets/images/p9.webp"
import p10 from "../../assets/images/p10.webp"

import { CustomArrowButton as Button } from "../../CommonComponents/button/Button";

const ECommerceProduct = ({ setBookingFlag }) => {
  const { id } = useParams();

  let title =
    id == 0
      ? "Wroclaw, Poland"
      : id == 1
      ? "New York, USA"
      : id == 2
      ? "Los Angeles, USA"
      : id == 3
      ? "Milan, Italy"
      : id == 4
      ? "Berlin, Germany"
      : id == 5
      ? "Sofia, Bulgaria"
      : id == 6
      ? "Manila, Philippines"
      : id == 7
      ? "Bengaluru, India"
      : id == 8
      ? "New Delhi, India"
      : id == 9
      ? "Beijing, China"
      : id == 10
      ? "Hong kong, China"
      : id == 11
      ? "Bangkok, Thailand"
      : "Dhaka, Bangladesh"

  return (
    <div className="ecommerce-product-section">
      <div className="ecommerce-product-container">
        <div className="top-left">
        <img src={p1} className="p1" />
        <img src={p2} className="p2" />
        </div>
        <div className="top-right">
        <img src={p3} className="p3" />
        <img src={p4} className="p4" />
        </div>
        <div className="content">
          <div className="heading">
            eCommerce Product
            <br />
            Photography service in <span>{title}</span>
          </div>
          <div className="text">
            Looking for reliable eCommerce photography services in{" "}
            <span>{title}</span>?
            <br />
            Look no further! We guarantee transparent pricing with no hidden
            fees, and consistent
            <br />
            high-quality results across all your products and collections.
          </div>
          <Button
            className="elevate-btn"
            title="Get in Touch"
            setBookingFlag={setBookingFlag}
          />
        </div>
        <div className="bottom">
        <div className="bottom-left">
        <img src={p5} className="p5" />
        <span className="bottom-left-img">
        <img src={p6} className="p6" />
        <img src={p7} className="p7" />
        </span>
        </div>
        <div className="bottom-right">
        <img src={p8} className="p8" />
        <span className="bottom-right-img">
        <img src={p9} className="p9" />
        <img src={p10} className="p10" />
        </span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ECommerceProduct;
